
import { defineComponent } from 'vue'
import ThankSaction from '@/components/sections/ThankSaction.vue'

export default defineComponent({
  name: 'Thank',
  components: {
    ThankSaction
  }
})
