
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'Thank',
  data () {
    return {
      isPayment: false,
      translateData: {}
    }
  },
  components: {
    Main,
    RefLink
  },
  mounted () {
    this.getProfile()
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('to_mainpage', 'thank_you', 'deposit_success')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToHome () {
      this.$router.push('/dashboard/home')
    },
    getProfile (data) {
      this.$store.dispatch('getPayments/getProfile', data).then(
        (response) => {
          this.isPayment = response.data.has_payments
          localStorage.setItem('has_payments', this.isPayment)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
